/* Preloader */

.handle-preloader{
	background-color:#e4c590;
}

.handle-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
}

.preloader-close{
  position: fixed;
  z-index: 99999999;
  font-size: 18px;
  background: #fff;
  width: 30px;
  height: 30px;
  color:#000000;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  right: 15px;
  top: 15px;
  border-radius: 0%;
}

.handle-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

.handle-preloader .animation-preloader .spinner{
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  height: 150px;
  margin: 0 auto 45px auto;
  width: 150px;
}

.handle-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before{
  animation: letters-loading 4s infinite;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top:0;
  position: absolute;
  
}

.handle-preloader .animation-preloader .txt-loading .letters-loading{
  font-weight: 500;
  letter-spacing: 15px;
  display: inline-block;
  position: relative;
  font-size: 70px;
  line-height: 70px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  
  -webkit-text-stroke-color: rgba(17, 17, 17, 0.5); 
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {animation-delay: 0.2s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {animation-delay: 0.4s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {animation-delay: 0.6s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {animation-delay: 0.8s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before { animation-delay: 1s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before { animation-delay: 1.2s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before { animation-delay: 1.4s;}
.handle-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.preloader .loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}



.handle-preloader .animation-preloader .txt-loading .letters-loading:before{
  color: #111111;
}

.handle-preloader .animation-preloader .spinner{
  border: 3px solid #ffffff;
  border-top-color: rgba(17, 17, 17, 1); 
}

/* AnimaciÃƒÆ’Ã‚Â³n del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .handle-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}
@media screen and (max-width: 500px) {
  .handle-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }
  .handle-preloader .animation-preloader .txt-loading .letters-loading {font-size: 30px; letter-spacing: 10px;}
}

